import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { FaInfoCircle } from "react-icons/fa";
import Link from "./link";
import PricingCard from "./pricing-card";

const PricingSection = ({
	mEssPrice,
	mPrePrice,
	mProPrice,
	mPmEssPrice,
	mPmPrePrice,
	mPmProPrice,
	oEssPrice,
	oPrePrice,
	oProPrice,
	oCpEssPrice,
	oCpPrePrice,
	oCpProPrice,
	dubai,
	text1,
	text2,
	text3,
	customText,
	webDesignEastbourne,
	btnText,
	btnLink,
}) => {
	const mEssPriceNew = mEssPrice || "£995";
	const mPmEssPriceNew = mPmEssPrice || "£85";
	const mPrePriceNew = mPrePrice || "£1245";
	const mPmPrePriceNew = mPmPrePrice || "£115";
	const mProPriceNew = mProPrice || "£1995";
	const mPmProPriceNew = mPmProPrice || "£145";

	const oEssPriceNew = oEssPrice || "£2995";
	const oCpEssPriceNew = oCpEssPrice || "£45";
	const oPrePriceNew = oPrePrice || "£4495";
	const oCpPrePriceNew = oCpPrePrice || "£45";
	const oProPriceNew = oProPrice || "£5995";
	const oCpProPriceNew = oCpProPrice || "£45";

	const text1New =
		text1 ||
		"For small businesses and sole traders looking for their first website";
	const text2New =
		text2 ||
		"For ambitious companies looking to take their website to the next level";
	const text3New =
		text3 || "For established businesses requiring an advanced solution";
	const customTextNew = customText || "Have a custom website project?";

	const [monthly, setMonthly] = useState(true);

	const handleClickMonthly = () => {
		setMonthly(true);
	};
	const handleClickOneTime = () => {
		setMonthly(false);
	};
	const h2Style = {
		paddingTop: "1.5em",
	};

	const pStyle = {
		paddingBottom: "1.5em",
	};

	return (
		<section className="py-5 py-lg-7">
			<div
				className="pb-lg-5 position-relative"
				style={{
					overflow: "hidden",
				}}
			>
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary display-5  text-center pb-5">
								PRICING
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-center pb-5">
						<Col className="text-center">
							<div
								style={{
									borderRadius: "100rem",
									backgroundColor: "white",
									padding: "10px",
								}}
								className="d-inline-block mx-auto "
							>
								<div
									style={{ borderRadius: "100rem" }}
									className={`d-inline-block source-normal py-2 px-3  px-md-4 ${
										monthly ? "bg-pricing-blue" : "bg-pricing-white"
									}  `}
									onClick={handleClickMonthly}
								>
									Monthly
								</div>
								<div
									style={{ borderRadius: "100rem" }}
									className={`d-inline-block source-normal py-2 px-md-4 px-3 ${
										monthly ? "bg-pricing-white" : " bg-pricing-blue"
									}  `}
									onClick={handleClickOneTime}
								>
									Up-front
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Container className="pricing-heading position-relative">
				<Row className={` justify-content-center ${monthly ? "" : "d-none"}`}>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="secondary"
						title="Essential"
						subText={text1New}
						price={mPmEssPriceNew}
						monthly
						setupFee={mEssPriceNew}
						features={[
							"Up to 15 pages + Unlimited Blog Posts",
							" GatsbyJS Website",

							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									WordPress Content Management <br /> System{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Blogging System only</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Unlimited Images, Audio, File <br /> content{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Maximum File Size restrictions apply
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Fast and Secure Webhosting{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													UK based WordPress hosting with Krystal
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Content Delivery Network{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Via Fastly</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									SSL Certificate
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Via Let's Encrypt</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,

							"Custom forms",
							"Google Analytics",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Google Tag Manager Setup{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Bespoke integration with any supported analytics
													platform
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
						]}
					/>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="dark-blue"
						middle
						title="Premium"
						subText={text2New}
						price={mPmPrePriceNew}
						monthly
						setupFee={mPrePriceNew}
						features={[
							<span>
								Everything in <span className="ssp-bold"> Essential</span>
							</span>,
							"Up to 30 pages",

							"Monthly Analytics Report",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Basic SEO{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													We will ensure all the correct Meta Tags and Schema
													Markup is built in from the start
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							// <span className="d-flex align-items-center">
							//   <p className="pb-0 mb-0 d-inline-block">
							//     WordPress Content Management System with customisable fields
							//     <OverlayTrigger
							//       placement="top"
							//       overlay={
							//         <Popover id={`popover-positioned-top`}>
							//           <Popover.Body>
							//             Fully customisable fields include (but not limited to)
							//             Timetables, Reviews, Certifications, customer
							//             documents etc
							//           </Popover.Body>
							//         </Popover>
							//       }
							//     >
							//       <FaInfoCircle
							//         style={{ width: "10px", color: "#40B4E5" }}
							//         className="ms-2 d-inline-block"
							//       />
							//     </OverlayTrigger>
							//   </p>
							// </span>,
						]}
					/>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="light-blue"
						title="Pro"
						subText={text3New}
						price={mPmProPriceNew}
						monthly
						setupFee={mProPriceNew}
						features={[
							<span>
								Everything in <span className="ssp-bold"> Premium</span>
							</span>,
							"Up to 100 pages",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Sell products or take online <br /> payments{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Via Shopify or Strip
													<br />
													Separate subscription required
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Sell online courses or membership <br /> section{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Via Memberspace and Stripe
													<br />
													Separate subscription required
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Monthly testing and updates on new browser updates{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Includes new mobile devices released to the market
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Advanced SEO{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body className="mb-0 pb-0">
													<ul className="mb-0 pl-3 pb-2">
														<li>
															We will ensure all the correct Meta Tags and
															Schema Markup is built in from the start
														</li>
														<li className="pt-2">
															Advice on keyword strategy, word count required
															for each page, headings, images and keyword
															density
														</li>
													</ul>
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
						]}
					/>
				</Row>
				<Row className={`justify-content-center ${monthly ? "d-none" : ""}`}>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="secondary"
						title="Essential"
						subText={text1New}
						price={oEssPriceNew}
						hPrice={oCpEssPriceNew}
						features={[
							"Up to 15 pages + Unlimited Blog Posts",
							" GatsbyJS Website",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									WordPress Content Management <br /> System{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Blogging System only</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Unlimited Images, Audio, File <br /> content{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Maximum File Size restrictions apply
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Fast and Secure Webhosting{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													UK based WordPress hosting with Krystal
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Content Delivery Network{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Via Fastly</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,

							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									SSL Certificate
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>Via Let's Encrypt</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							"Contact forms",
							"Google Analytics",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Google Tag Manager Setup{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Bespoke integration with any supported analytics
													platform
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
						]}
					/>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="dark-blue"
						middle
						title="Premium"
						subText={text2New}
						price={oPrePriceNew}
						hPrice={oCpPrePriceNew}
						features={[
							<span>
								Everything in <span className="ssp-bold"> Essential</span>
							</span>,
							"Up to 30 pages",

							"Monthly Analytics Report",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Basic SEO{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													We will ensure all the correct Meta Tags and Schema
													Markup is built in from the start
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							// <span className="d-flex align-items-center">
							//   <p className="pb-0 mb-0 d-inline-block">
							//     WordPress Content Management System with customisable fields
							//     <OverlayTrigger
							//       placement="top"
							//       overlay={
							//         <Popover id={`popover-positioned-top`}>
							//           <Popover.Body>
							//             Fully customisable fields include (but not limited to)
							//             Timetables, Reviews, Certifications, customer
							//             documents etc
							//           </Popover.Body>
							//         </Popover>
							//       }
							//     >
							//       <FaInfoCircle
							//         style={{ width: "10px", color: "#40B4E5" }}
							//         className="ms-2 d-inline-block"
							//       />
							//     </OverlayTrigger>
							//   </p>
							// </span>,
						]}
					/>
					<PricingCard
						btnText={btnText}
						btnLink={btnLink}
						dubai={dubai}
						btnColour="light-blue"
						title="Pro"
						subText={text3New}
						price={oProPriceNew}
						hPrice={oCpProPriceNew}
						features={[
							<span>
								Everything in <span className="ssp-bold"> Premium</span>
							</span>,
							"Up to 100 pages",
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Sell products or take online <br /> payments{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Via Shopify or Stripe
													<br />
													Separate subscription required
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Sell online courses or membership <br /> section{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Via Memberspace and Stripe
													<br />
													Separate subscription required
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,

							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Monthly testing and updates on new browser updates{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body>
													Includes new mobile devices released to the market
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
							<span className="d-flex align-items-center">
								<p className="pb-0 mb-0 d-inline-block ssp-regular">
									Advanced SEO{" "}
									<OverlayTrigger
										placement="top"
										overlay={
											<Popover id={`popover-positioned-top`}>
												<Popover.Body className="mb-0 pb-0">
													<ul className="mb-0 pl-3 pb-2">
														<li>
															We will ensure all the correct Meta Tags and
															Schema Markup is built in from the start
														</li>
														<li className="pt-2">
															Advice on keyword strategy, word count required
															for each page, headings, images and keyword
															density
														</li>
													</ul>
												</Popover.Body>
											</Popover>
										}
									>
										<FaInfoCircle
											style={{ width: "10px", color: "#40B4E5" }}
											className="ms-2 d-inline-block"
										/>
									</OverlayTrigger>
								</p>
							</span>,
						]}
					/>
				</Row>
				<Row>
					<Col className="text-center mt-xl-5">
						<h2 className="ssp-bold">{customTextNew}</h2>
						{!dubai && (
							<p>
								Looking for a completely bespoke web application solution? Find
								out more about our web development services.
							</p>
						)}
						{dubai && (
							<p>
								Looking for a trusted team to help create a bespoke web
								application solution? Find out more about our web development
								services.
							</p>
						)}
						<Button
							className="mt-5 fs-5 cta-btn w-100 w-md-auto py-2 px-4 white-link"
							variant="primary"
							as={Link}
							to={`${dubai ? "/dubai/web-development" : "/web-development"}`}
						>
							{/* {webDesignEastbourne
                ? "Web Development Services"
                : "Find out more"} */}
							Web Development Services
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PricingSection;
